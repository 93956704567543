
.logo {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
    margin-left: 25%;
 
   }

.logo img {
    
display: block;
width: 180px;
height: 180px;
max-width: 100%;
max-height: 100%;



}